/**
 * Returns a message based on the provided error type from an
 * attempted user signup to the API endpoint /v4/users, and some
 * custom types.
 *
 * @param {String} error - Either an error code from the BE response or a custom type that web defines
 * @param {Function} trans - Translation function
 * @returns {String} UI message corresponding to the error code
 */
module.exports = function(error, trans) {
  let message;
  switch (error.toLowerCase()) {
    case "1006": // RequiredParamMissing
      message = trans("Please make sure all fields are filled in.");
      break;
    case "1027": // Internal error
      message = trans(
        "Sorry, based on the information you have provided, we couldn't create an account for you."
      );
      break;
    case "1171": // AccountAlreadyExists Facebook
      message = trans(
        "An account with this email address may already exist. Try using another email address."
      );
      break;
    case "fixerrors":
      message = trans("Please fix any errors to continue.");
      break;
    case "unknown":
    default:
      message = trans("Sorry, something went wrong. Please try again.");
      break;
  }
  return message;
};
