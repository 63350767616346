/**
 * Returns a message based on the provided error type from an
 * attempted user login to the API endpoint /v4/sessions.
 *
 * @param {String} error - Either an error code from the BE response or a custom type that web defines
 * @param {Function} trans - Translation function
 * @returns {String} UI message corresponding to the error code
 */
module.exports = function(error, trans) {
  let message;
  switch (error.toLowerCase()) {
    case "1006": // RequiredParamMissing
      message = trans("Please make sure all fields are filled in.");
      break;
    case "1029": // PermissionDenied
      message = trans("Login failed. Invalid user ID or password.");
      break;
    case "1172": // AccessDenied
      message = trans(
        "An account with this email address may already exist. Try signing in with your Google, Apple or Facebook account instead."
      );
      break;
    case "fblogin":
      message = trans(
        "Error logging in through Facebook. Please refresh and try again."
      );
      break;
    case "unknown":
    default:
      message = trans("Sorry, something went wrong. Please try again.");
      break;
  }
  return message;
};
