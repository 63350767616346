import PropTypes from "prop-types";
import React from "react";
import { useTrans, useFeatureFlags } from "../../hooks";
import ThirdPartyAuth from "./ThirdPartyAuth";
import classNames from "classnames";
import { Button, ButtonVariant } from "@wattpad/web-ui-library";

const ThirdPartySignup = ({
  focus,
  nextUrl = "/home",
  toggleForm,
  toggleFormView
}) => {
  const { trans } = useTrans();
  const featureFlags = useFeatureFlags();
  const policy_agreement = trans("By continuing, you agree to Wattpad's <a href='/terms'>Terms of Service</a> and <a href='/privacy'>Privacy Policy</a>."); // prettier-ignore

  //NOTE: after rollout update test for hrText
  const hrText = featureFlags.NEW_ONBOARDING_1 ? trans("or") : "OR";
  const hrWithTextNew = featureFlags.NEW_ONBOARDING_1
    ? "hr-margin-new"
    : "hr-margin";
  const signupText = featureFlags.NEW_ONBOARDING_1
    ? trans("Sign up with email")
    : trans("Sign up with Email");

  const onEmailSignup = () => {
    if (featureFlags.NEW_ONBOARDING_1) {
      window.te.push("event", "landing", null, null, "signup", {
        platform: "email"
      });
    }
    toggleFormView();
  };

  return (
    <>
      <ThirdPartyAuth source="signup" nextUrl={nextUrl} focus={focus} />
      <div className={classNames("hr-with-text", hrWithTextNew)}>
        <div className="horizontal-line" />
        <span className="or"> {hrText} </span>
        <div className="horizontal-line" />
      </div>
      <button
        className={classNames("btn-block btn-primary submit-btn", {
          "submit-btn-margin-24": featureFlags.NEW_ONBOARDING_1
        })}
        onClick={onEmailSignup}
      >
        {signupText}
      </button>
      {featureFlags.NEW_ONBOARDING_1 ? (
        <Button
          variant={ButtonVariant.TERTIARY}
          onClick={toggleForm}
          label={trans("I already have an account")}
          fullWidth
        />
      ) : (
        <>
          <p>
            {trans("If you already have an account, ")}
            <button className="login" onClick={toggleForm}>
              {trans("Log in.")}
            </button>
          </p>

          <span
            className="policy-agreement"
            dangerouslySetInnerHTML={{
              __html: policy_agreement
            }}
          />
        </>
      )}
    </>
  );
};

ThirdPartySignup.propTypes = {
  focus: PropTypes.bool,
  nextUrl: PropTypes.string,
  toggleForm: PropTypes.func.isRequired,
  toggleFormView: PropTypes.func.isRequired
};

export default ThirdPartySignup;
